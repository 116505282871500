// Vérification du chargement des fonts
if (document && document.fonts) {
    setTimeout(function () {
        Promise.all(
            [
                document.fonts.load('700 1em "Poppins"'), // Header 1
                document.fonts.load('600 1em "Poppins"'), // Header 2-3 / screen-title
                document.fonts.load('800 1em "Lato"'), // caption-bold / emphasys
                document.fonts.load('700 1em "Lato"'), // subtitle / button-default-small / notice-bold
                document.fonts.load('600 1em "Lato"'), // caption / value / value-large
                document.fonts.load('500 1em "Lato"'), // notice
                document.fonts.load('400 1em "Lato"'), // body
                document.fonts.load('500 1em "Lato"'), // notice
            ]
        ).then(
            () => {
                document.querySelector('.main-container').classList.add('loaded')
            }
        )
    }, 0)
} else {
    document.querySelector('.main-container').classList.add('loaded')
}
